/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'

let token = '',
  usrRole = ''

export function AsideMenuMain() {
  let role = localStorage.getItem('portalUserDataRole')

  if (!role) {
    window.location.reload()
  } else {
    usrRole = JSON.parse(localStorage.getItem('portalUserDataRole') || '')
  }

  return (
    <>
      <div
        className='img-fluid p-4 mx-4 d-sm-block d-md-none'
        style={{background: 'white', borderRadius: '10%'}}
      >
        <img alt='Logo' width='100' height='30' src='/media/logos/sigma_logo.jpg' className='' />
      </div>
      <AsideMenuItem
        to='/home'
        // icon='/media/icons/duotune/art/art002.svg'
        title='Home'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/coding/cod002.svg'
      />

      {(usrRole.includes('Administrator') ||
        usrRole.includes('Buyer') ||
        usrRole.includes('Staff') ||
        usrRole.includes('Pricer')) && (
        <>
          {/* <AsideMenuItem
            to='/product'
            icon='/media/icons/duotune/art/art002.svg'
            title='Product'
            fontIcon='bi-app-indicator'
          /> */}
           <AsideMenuItem
            to='/product'
            icon='/media/icons/duotune/art/art002.svg'
            title='Product'
            fontIcon='bi-app-indicator'
          />
             <AsideMenuItem
            to='/prodId'
            icon='/media/icons/duotune/files/fil001.svg'
            title='Product Identifier'
            fontIcon='bi-layers'
          />
          <AsideMenuItem
            to='/supplier'
            icon='/media/icons/duotune/general/gen019.svg'
            title='Supplier'
            fontIcon='bi-layers'
          />

          <AsideMenuItem
            to='/poList'
            icon='/media/icons/duotune/ecommerce/ecm001.svg'
            title='PO'
            fontIcon='bi-layers'
          />
          <AsideMenuItem
            to='/tasks'
            icon='/media/icons/duotune/technology/teh002.svg'
            title='Tasks'
            fontIcon='bi-layers'
          />
          <AsideMenuItem
            to='/allmarkettrend'
            icon='/media/icons/duotune/technology/teh0011.svg'
            title='Market Trends'
            fontIcon='bi-layers'
          />

          <AsideMenuItem
            to='/Comments'
            icon='/media/icons/duotune/communication/com008.svg'
            title='Comments'
            fontIcon='bi-layers'
          />
          <AsideMenuItem
            to='/AllBackgroundList'
            icon='/media/icons/duotune/communication/com0012.svg'
            title='Background List'
            fontIcon='bi-layers'
          />
          <AsideMenuItemWithSub
            to='/reports'
            title='Prices'
            fontIcon='bi-chat-left'
            icon='/media/icons/duotune/coding/cod001.svg'
          >
            {(usrRole.includes('Buyer') ||
              usrRole.includes('Staff') ||
              usrRole.includes('Administrator')) && (
              <>
                {' '}
                <AsideMenuItemWithSub
                  to='/Buyer'
                  title='Buyer'
                  fontIcon='bi-chat-left'
                  icon='/media/icons/duotune/coding/buyer.svg'
                >
                  <AsideMenuItem
                    to='/reports/pricecomparison'
                    title='Price Comparison'
                    hasBullet={true}
                  />
                  <AsideMenuItem
                    to='/reports/buyerwatchlist'
                    title='View Watchlist'
                    hasBullet={true}
                  />
                </AsideMenuItemWithSub>
              </>
            )}
            {usrRole.includes('Administrator') && (
              <AsideMenuItemWithSub
                to='/Pricer'
                title='Reviewer'
                fontIcon='bi-chat-left'
                icon='/media/icons/duotune/coding/reviewer.svg'
              >
                <AsideMenuItem to='/reports/CompetitorPrice' title='RDS' hasBullet={true} />
                <AsideMenuItem to='/reports/Sigma' title='Sigma' hasBullet={true} />
              </AsideMenuItemWithSub>
            )}

            {(usrRole.includes('Pricer') || usrRole.includes('Administrator')) && (
              <AsideMenuItemWithSub
                to='/Pricer'
                title='Pricer'
                fontIcon='bi-chat-left'
                icon='/media/icons/duotune/coding/cod004.svg'
              >
                <AsideMenuItem
                  to='/reports/createwatchlist'
                  title='Create watchlist'
                  hasBullet={true}
                />
                <AsideMenuItem
                  to='/reports/viewwatchlist'
                  title='View watchlist'
                  hasBullet={true}
                />
                <AsideMenuItem to='/reports/priceupdate' title='Price Update' hasBullet={true} />
              </AsideMenuItemWithSub>
            )}

            {/* {usrRole.includes('Administrator') && (
              <AsideMenuItemWithSub
                to='/Pricer'
                title='Reviewer'
                fontIcon='bi-chat-left'
                icon='/media/icons/duotune/coding/cod002.svg'
              >
                <AsideMenuItem to='/reports/CompetitorPrice' title='RDS' hasBullet={true} />
                <AsideMenuItem to='/reports/Sigma' title='Sigma' hasBullet={true} />
              </AsideMenuItemWithSub>
            )} */}

            {/* <AsideMenuItem to='/reports/Runrate' title='Runrate' hasBullet={true} /> */}
            {/* <AsideMenuItem to='/reports/buyerswatchlist' title='Watchlist' hasBullet={true} /> */}
          </AsideMenuItemWithSub>
        </>
      )}
      <AsideMenuItemWithSub
        to='/report'
        title='Reports'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/coding/cod010.svg'
      >
        <AsideMenuItem
          to='/runrate'
          icon='/media/icons/duotune/communication/com001.svg'
          title='Runrate'
          fontIcon='bi-layers'
        />
        <AsideMenuItem
          to='/powerbi'
          icon='/media/icons/duotune/general/gen012.svg'
          title='Price Comparison'
          fontIcon='bi-layers'
        />
      </AsideMenuItemWithSub>
      {/* <AsideMenuItem
        to='/PriceComparison'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Price Review'
        fontIcon='bi-layers'
      />*/}
      {/* <AsideMenuItem
        to='/runrate'
        icon='/media/icons/duotune/general/gen027.svg'
        title='Runrate'
        fontIcon='bi-layers'
      />  */}
      {/* <AsideMenuItem
        to='/ProductAnalytical'
        icon='/media/icons/duotune/general/gen022.svg'
        title=' Product Analytics'
        fontIcon='bi-layers'
      /> */}

      {usrRole.includes('RDS-Reviewer') && !usrRole.includes('Administrator') && (
        <>
          {' '}
          <AsideMenuItem
            to='/reports/CompetitorPrice'
            title='RDS'
            hasBullet={false}
            icon='/media/icons/duotune/coding/cod002.svg'
          />
        </>
      )}
      {usrRole?.includes('Sigma-Reviewer') && !usrRole.includes('Administrator') && (
        <>
          {' '}
          <AsideMenuItem
            to='/reports/Sigma'
            title='Sigma'
            hasBullet={false}
            icon='/media/icons/duotune/coding/cod002.svg'
          />
        </>
      )}

      {usrRole.includes('Administrator') && (
        <AsideMenuItemWithSub
          to='/admin'
          title='Admin'
          fontIcon='bi-chat-left'
          icon='/media/icons/duotune/coding/admin.svg'
        >
          <AsideMenuItem to='/admin/role' title='Roles ' hasBullet={true} />
          <AsideMenuItem to='/admin/user' title='Users ' hasBullet={true} />
          <AsideMenuItem to='/admin/sList' title='Suppliers' hasBullet={true} />
          <AsideMenuItem to='/tag' title='Tag' hasBullet={true} />

          {/* <AsideMenuItem to='/admin/supplierListNew' title='Suppliers New' hasBullet={true} /> */}

          {/* <AsideMenuItem to='/admin/pList' title='Products ' hasBullet={true} /> */}
          <AsideMenuItem to='/admin/settings' title='Settings ' hasBullet={true} />
          <AsideMenuItem to='/admin/pList' title='Products ' hasBullet={true} />
          <AsideMenuItem to='/admin/loginstats' title='Login Summary ' hasBullet={true} />

          <AsideMenuItem
            to='/admin/predefinedComment'
            title='Predefined Comment'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
      )}

      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
    </>
  )
}
