import {useEffect, useState} from 'react'
import {message} from 'antd'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Redirect} from 'react-router-dom'
import {useFormik} from 'formik'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {MasterLayout} from '../../../_metronic/layout/MasterLayout'
import {PrivateRoutes} from '../../routing/PrivateRoutes'
import {ResetPassword} from './ResetPassword'
import validator from 'validator'
import {mainConfig} from '../../../config/mainConfig'
import { useHistory } from 'react-router-dom';

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: 'admin@demo.com',
  password: 'demo',
}
let userRole=localStorage.getItem('portalUserDataRole')
const errormessage = 'Email address does not exist in the system'
export const Login=()=> {
  const history = useHistory();
  const [loading, setLoading] = useState(false)
  const [toProduct, setToProduct] = useState(false)
  const [ToFirstUser, setToFirstUser] = useState(false)
  const [email, setEmail] = useState<any>('')
  const [password, setPassword] = useState<any>('')
  const [emailForgot, setEmailForgot] = useState<any>('')
  const [ErrEmailForgot, setErrEmailForgot] = useState<any>(false)
  const [emailErr, setemailErr] = useState<any>(false)
  const [ErrEmail, setErrEmail] = useState<any>(false)
  const [ErrPassword, setErrPassword] = useState<any>(false)
  const [forgotPassword, setforgotPassword] = useState<any>(false)
  const [ErrEmailForgotEmpty, setErrEmailForgotEmpty] = useState<any>(true)
  const [showpassword, setshowpassword] = useState<any>(false)
  useEffect(() => {
    setToProduct(false)
    setforgotPassword(false)
    setToFirstUser(false)
    setLoading(false)
  }, [])
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)

      const user = {
        email: email,
        password: password,
        
      }
      let dta = JSON.stringify(user)

      fetch(`${mainConfig.MAIN_ENDPOINT}/api/login`, {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          header: 'Access-Control-Allow-Origin: *',
        },
        body: dta,
      })
        .then((data: any) => {
          data.json().then(function (resp: any) {
            var role :any[]
            if (resp.data) {
              // resp.data.roles.map((p: any) => {
              //   // if (p == 'Administrator') {
              //     role.push(p)
              //   // }
              // })
              localStorage.setItem('portalUserDataToken', JSON.stringify(resp.data.token))
              localStorage.setItem('portalUserDataRole', JSON.stringify(resp.data.role))

              setToProduct(true)

            }
          })
        })
        .catch(function (error) {})
    },
  })

  const handleSubmit = (e: any) => {
    e.preventDefault()
    if (email != '' && password != '') {
      let result = validator.isEmail(email)
      if (result == true) {
        setemailErr(false)
        setLoading(true)
        const user = {
          email: email,
          password: password,
        }
        let dta = JSON.stringify(user)

        fetch(`${mainConfig.MAIN_ENDPOINT}/api/login`, {
          method: 'post',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            header: 'Access-Control-Allow-Origin: *',
          },
          body: dta,
        })
          .then((data: any) => {
            data.json().then(function (resp: any) {
              var role = new String('')
              if (resp.data && resp.success) {
                
                // resp.data.roles.map((p: any) => {
                //   if (p == 'Administrator') {
                //     role = p
                //   }
                // })
                console.log("user login")
                localStorage.setItem('userData', JSON.stringify(resp.data))
                localStorage.setItem('portalUserDataToken', JSON.stringify(resp.data.token))
                localStorage.setItem('portalUserDataRole', JSON.stringify( resp.data.roles))
                localStorage.setItem('portalUserSession_Id',  (resp.data.session_id))
 
                message.success(resp.message)
                history.push('/home');
                
                setLoading(false)
                if (resp.data.first_attempt) {
                  localStorage.setItem('userData', JSON.stringify(resp.data))
                  localStorage.setItem('portalUserDataToken', JSON.stringify(resp.data.token))
                  localStorage.setItem('portalUserDataRole', JSON.stringify( resp.data.roles))
                  localStorage.setItem('portalUserAttempt', JSON.stringify(true))
                  localStorage.setItem('portalUserSession_id',(resp.data.session_id))

                  setLoading(false)
                  setTimeout(function () {
                    setToFirstUser(true)
                  }, 1000)
                } else {
                  localStorage.setItem('userData', JSON.stringify(resp.data))
                  localStorage.setItem('portalUserAttempt', JSON.stringify(false))
                  localStorage.setItem('portalUserDataToken', JSON.stringify(resp.data.token))
                  localStorage.setItem('portalUserDataRole', JSON.stringify( resp.data.roles))
                  localStorage.setItem('portalUserSession_id',  (resp.data.session_id))

                  setToProduct(true)
                  setLoading(false)
                }
              } else {
                message.error(resp.message)
                setLoading(false)
              }
            })
          })
          .catch(function (error) {
            message.error(' Login Unsuccesfull !! ')
            setLoading(false)
          })
      } else {
        setemailErr(true)
      }
    } else {
      if (email == '') {
        setErrEmail(true)
      }
      if (password == '') {
        setErrPassword(true)
      }
    }
  }
  const handleSubmitEmail=(e: any)=> {
    e.preventDefault()
    if (emailForgot != '') {
      const user = {
        email: emailForgot,
      }
      let dta = JSON.stringify(user)
      try {
        fetch(`${mainConfig.MAIN_ENDPOINT}/api/reset-password-email`, {
          method: 'post',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: dta,
        })
          .then((data: any) => {
            data.json().then(function (resp: any) {
              if (resp.success == true) {
                message.success(resp.message)
                setEmailForgot('')
                setforgotPassword(false)
              } else {
                message.error(errormessage)
                setEmailForgot('')
                setforgotPassword(false)
              }
            })
          })
          .catch(function (error) {
            console.log('Request failed', error)
          })
      } catch (err) {
        console.log('ERR>>>', err)
      }
    } else {
      setErrEmailForgotEmpty(true)
    }
  }
  
  return ToFirstUser == true ? (
    <ResetPassword />
  ) : toProduct == true ? (
    <MasterLayout>
    {/* {
      userRole &&  <Redirect exact to={'/home'} />
    } */}
     
      <PrivateRoutes />
    </MasterLayout>
  ) : forgotPassword == true ? (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      style={{background: '#ffffff'}}
    >
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        <a href='#' className='mb-12'>
          <img alt='Logo' src={toAbsoluteUrl('/media/logos/sigma_logo.jpg')} className='h-55px' />
        </a>
        <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
          <form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_login_password_reset_form'
            onSubmit={handleSubmitEmail}
          >
            <div className='text-center mb-10'>
              <h1 className='text-dark mb-3'>Forgot Password ?</h1>
              <div className='text-gray-400 fw-bold fs-4'>
                Enter your email to reset your password.
              </div>
            </div>
            <div className='fv-row mb-10'>
              <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
              <input
                onChange={(e) => {
                  setEmailForgot(e.target.value)

                  if (validator.isEmail(e.target.value)) {
                    setErrEmailForgot(false)
                  } else {
                    setErrEmailForgot(true)
                  }
                }}
                placeholder='Email'
                onFocus={() => setErrEmailForgotEmpty(false)}
                value={emailForgot}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {'is-invalid': formik.touched.email && formik.errors.email},
                  {
                    'is-valid': formik.touched.email && !formik.errors.email,
                  }
                )}
                type='email'
                name='email'
                autoComplete='off'
              />
              {ErrEmailForgot == true ? (
                <span role='alert' className='text-danger'>
                  Enter Valid Email Address
                </span>
              ) : null}
              {ErrEmailForgotEmpty == true ? (
                <span role='alert' className='text-danger'>
                  Email Required
                </span>
              ) : null}
            </div>
            <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
              <button
                type='submit'
                id='kt_password_reset_submit'
                className='btn btn-lg btn-primary fw-bolder me-4'
              >
                <span className='indicator-label'>Submit</span>
                {loading == true && (
                  <span
                    className='spinner-grow spinner-grow-sm'
                    role='status'
                    aria-hidden='true'
                  ></span>
                )}
              </button>
              <button
                type='button'
                className='btn btn-lg btn-light-primary fw-bolder'
                onClick={() => {
                  setforgotPassword(false)
                  setEmailForgot('')
                  setErrEmailForgotEmpty(false)
                  setErrEmailForgot(false)
                }}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  ) : (
    <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        <a href='#' className='mb-12'>
          <img alt='Logo' src={toAbsoluteUrl('/media/logos/sigma_logo.jpg')} className='h-55px' />
        </a>
        <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
          <form className='form w-100' onSubmit={handleSubmit} noValidate id='kt_login_signin_form'>
            <div className='text-center mb-10'>
              <h1 className='text-dark mb-3'>Sign In to Product Portal</h1>
            </div>
            <div className='fv-row mb-10'>
              <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
              <input
                onChange={(e) => setEmail(e.target.value)}
                placeholder='Email'
                onFocus={() => setErrEmail(false)}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {'is-invalid': formik.touched.email && formik.errors.email},
                  {
                    'is-valid': formik.touched.email && !formik.errors.email,
                  }
                )}
                type='email'
                name='email'
                autoComplete='off'
              />
              {emailErr == true && (
                <span role='alert' className='text-danger'>
                  {' '}
                  Please enter a valid email address{' '}
                </span>
              )}
              {ErrEmail == true ? (
                <span role='alert' className='text-danger'>
                  Email is Required
                </span>
              ) : null}
            </div>
            <div className='fv-row mb-10'>
              <div className='d-flex justify-content-between mt-n5'>
                <div className='d-flex flex-stack mb-2'>
                  <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
                </div>
              </div>
              <input
                type={showpassword ? 'text' : 'password'}
                autoComplete='off'
                placeholder='Password'
                onChange={(e) => setPassword(e.target.value)}
                onFocus={() => setErrPassword(false)}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {
                    'is-invalid': formik.touched.password && formik.errors.password,
                  },
                  {
                    'is-valid': formik.touched.password && !formik.errors.password,
                  }
                )}
              />
              <input
                type='checkbox'
                className='p-2 m-2'
                onClick={(e) => {
                  setshowpassword(!showpassword)
                }}
              />
              Show Password
              {ErrPassword == true ? (
                <span role='alert' className='text-danger'>
                  Password is Required
                </span>
              ) : null}
            </div>
            <button type='button' className='btn btn-link' onClick={() => setforgotPassword(true)}>
              Forgot Password ?
            </button>
            <div className='text-center'>
              <button
                type='submit'
                id='kt_sign_in_submit'
                className='btn btn-lg btn-primary w-100 mb-5'
                disabled={loading == true ? true : false}
              >
                {!loading && <span className='indicator-label'>Login</span>}
                {loading == true && (
                  <span
                    className='spinner-grow spinner-grow-sm'
                    role='status'
                    aria-hidden='true'
                  ></span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
